import React, { useRef } from "react"
import Slider from "react-slick"

export default function ClientesSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    return (
        <div className="slider_banner">
          
            <Slider {...settings}>
                <div className="main_slide">
                    <img className="w-100"  src="https://res.cloudinary.com/dv10simqm/image/upload/v1642006027/helios/HOME%20HELIOSDG/bg_home_2x_cb92bi.png" />
                    <p>A GLOBAL TECH & DIGITAL SOLUTIONS COMPANY</p>
                </div>
                {/* <div className="main_slide">
                    <img className="w-100"  src="https://res.cloudinary.com/dv10simqm/image/upload/v1641421767/helios/HOMESLIDER_01_vr1a8v.png" />
                    <p>A GLOBAL TECH & DIGITAL SOLUTIONS COMPANY</p>
                </div> */}
                {/* <div>
                    <img className="w-100"  src="https://res.cloudinary.com/dv10simqm/image/upload/v1641421767/helios/HOMESLIDER_01_vr1a8v.png" />
                </div> */}
                {/* <div>
                    <img className="w-100"  src="https://dummyimage.com/2732x573/000/fff" />
                </div>
                <div>
                    <img className="w-100"  src="https://dummyimage.com/2732x573/000/fff" />
                </div>
                <div>
                    <img className="w-100"  src="https://dummyimage.com/2732x573/000/fff" />
                </div> */}
            </Slider>
        </div>
    )
}

















